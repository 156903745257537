body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card,
.form-control {
  opacity: 1;
}

.card {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgb(90 116 148/40%), rgb(210 210 249) 4px 4px 0 0;
  border-radius: 10px;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem;
  font-family: "Courier New", Courier, monospace;
}

img {
  padding-bottom: 1rem;
  width: 30%;
  padding-top: 0.5rem;
  opacity: 0.88;
}

button._1E3Mb {
  background-color: #ffffff00;
  height: 3rem;
  color: #333;
  border: 1px solid #333;
  border-radius: 6px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
}

.form-label,
button._1E3Mb,
button._uMOg7 {
  display: flex;
  align-items: center;
  font-family: sans-serif;
}

h5 {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: small;
  font-weight: 400;
}

h6 {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: small;
  font-weight: 400;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked {
  background-color: rgba(28, 28, 225, 0.8);
  border-color: #1c1ce1;
  cursor: pointer;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input:focus,
.form-check-input:active {
  border-color: #1c1ce1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(187 192 229) !important;
}

.form-check-input {
  width: 1.6em;
  height: 1.2em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #202020;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  cursor: pointer;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:hover {
  box-shadow: rgb(210 210 249) 3px 3px 0 0;
  cursor: pointer;
}

.toggle-select {
  background-color: #f8f8f9;
  height: 2rem;
  color: #333;
  border-top: 1px solid #333;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  text-indent: 8px;
  display: flex;
}

button.back-button {
  height: 35px;
  width: fit-content;
  outline: none;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 10px;
  padding: 15px;
  background: #1c1ce1;
}

button.back-button:hover {
  background: #0df2fa;
  border: 1px solid #1c1ce1;
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
}

button.back-button a {
  padding-top: 4px;
}

button.AlgoButton2 {
  height: 50px;
  width: 220px;
  outline: none;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  background-color: #000000;
  display: flex;
}

button.AlgoButton {
  height: 50px;
  width: 320px;
  outline: none;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  background-color: #000000;
  display: flex;
}
button.AlgoButton a {
  display: flex;
  font-size: 15px;

}

button.AlgoButton:hover {
  background: #515860;
  border: 1px solid #121213;
  box-shadow: rgb(210 210 249) 2px 2px 0 0;
  cursor: pointer;
}

button.wc-button {
  height: 50px;
  width: 220px;
  outline: none;
  color: #fff;
  border: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
  background-color: #1677df;
  display: flex;
}

button.wc-button a {
  display: flex;
  font-size: 15px;
}

button.wc-button:hover {
  background: #4580bb;
  border: 1px solid #121213;
  box-shadow: rgb(210 210 249) 2px 2px 0 0;
  cursor: pointer;
}

button.home {
  margin: 0 auto;
  display: block;
}

button.home a {
  padding-top: 7px;
}

select#walletswitch {
  background-color: #fff;
  color: #5555e8;
  border: 1px solid #5555e8;
  border-radius: 6px;
  cursor: pointer;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;
  font-weight: 400;
  padding: 0.8em 0.6em;
  font-size: small;
  font-weight: 400;
  text-align: left;
}

select#walletswitch:focus,
select#walletswitch:active {
  background-color: #f8f8f9;
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
}

select#walletswitch:hover {
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
  background-color: #f8f8f9;
}
.toolTip:after {
  content: "?";
  display: inline-block;
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 0.8em;
  line-height: 0.8em;
  border-radius: 50%;
  margin-left: 6px;
  padding: 0.13em 0.2em 0.13em 0.2em;
  color: gray;
  border: 2px solid;
  text-decoration: none;
}

button._uMOg7 {
  background-color: #1c1ce1;
  color: #fff !important;
  display: inline-block;
  white-space: pre-wrap;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-decoration: none;
  height: 3rem;
  border: 1px solid #333;
  border-radius: 6px;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 700;
}

button._1E3Mb:hover {
  color: #1c1ce1;
  border: 1px solid #1c1ce1;
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
}

button._1E3Mb:active {
  background-color: #e5e5e5;
}

button.confirm-button {
  height: 35px;
  width: 100%;
  outline: none;
  color: #fff;
  border: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 5px;
  background: #1c1ce1;
}

button.confirm-button a {
  padding-top: 3px;
}

button.confirm-button:hover {
  background: #0df2fa;
  border: 1px solid #1c1ce1;
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
}

.card h3 {
  position: relative;
  min-height: 0.8em;
  background: #f8f8f9;
  padding: 0.8em 1.2em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.8);
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
    box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  margin-bottom: 0;
  overflow-wrap: break-word !important;
  flex-flow: wrap;
  white-space: normal;
  box-shadow: inset 0 0 0 1px rgb(34 36 38/22%), 0 0 0 0 transparent;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: small;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0.5rem;
}

.form-label {
  opacity: 1;
  padding: 0.8rem;
  padding-left: 0;
  padding-right: 0;
  font-size: smaller;
}

@media (max-width: 550px) {

  button.AlgoButton2 {
    width: 150px;
  }

  .form-label {
    opacity: 1;
    display: flex;
    padding: 0.8rem;
    padding-left: 0;
    padding-right: 0;
    font-size: smaller;
    font-family: sans-serif;
    flex-direction: column;
    align-content: stretch;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-top: 0.2rem;
    text-indent: 0.3rem;
    line-height: 1.5;
    color: #495057;
    background-color: #f8f8f9;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: monospace;
  }

  div#root {
    background-color: #f4f4f400;
    height: 100vh;
    padding: 3vh !important;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  margin-left: 1rem;
  text-indent: 0.3rem;
  line-height: 1.5;
  color: #495057;
  background-color: #f8f8f9;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: monospace;
}

br {
  display: none !important;
}

input.form-control:hover {
  background-color: #fff;
}

input.form-control:focus {
  color: #495057;
  background-color: #fff;
  border: 1px solid #1c1ce1;
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
  outline: 0;
}

button._uMOg7:active {
  background-color: #1616b4;
}

button._uMOg7:hover {
  background-color: rgba(28, 28, 225, 0.8);
  box-shadow: rgb(210 210 249) 4px 4px 0 0;
}

div#root,
html {
  background-color: #f4f4f400;
}

div#root {
  height: 100vh;
  padding: 7vh;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.button-section {
  margin: 3%;
}
.button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: royalblue;
}



.remove {
  margin: 5px;
  background-color: rgb(192, 53, 53);
}
.remove:hover {
  background-color: rgb(187, 43, 43);
}
section.slideshow{
  width: 80%;
  margin-left: 3.4em;
}
section.slideshow div.slide-holder{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-right: -7em;
}
section.slideshow section.current-slide{
  width: 1200px;
  height: 400px;
  margin: auto 30px;
}
section.slideshow section.previous-slide,
section.slideshow section.next-slide{
  width: 300px;
  height: 150px;
}
section.slideshow section.slide div.slide-thumbnail{
  width: 100%;
  height: 100%;
  background: #1c1ce1;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
  box-shadow: 5px 5px 6px #1c1ce1;
}